export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: 'image',
    label: 'field.image',
    thClass: 'text-left',
    tdClass: 'text-left',
    type: 'image',
  },
  {
    key: 'qrCode',
    label: 'field.qrCode',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: "itemCode",
    sortable: true,
    sortField: "itemCode",
    label: "field.itemCode",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "description",
    label: "field.description",
  },
  {
    key: "companyCode",
    label: "field.companyCode",
  },
  {
    key: "companyName",
    label: "field.companyName",
  },
  {
    key: "quantity",
    label: "field.quantity",
  },
  {
    key: "unitPrice",
    label: "field.unitPrice",
  },
  {
    key: "isAvailable",
    label: "field.available",
  },
  {
    key: "status",
    label: "field.status",
  },
  {
    key: "createdAt",
    sortable: true,
    sortField: "createdAt",
    label: "field.createdDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdBy",
    label: "field.createdBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedAt",
    sortable: true,
    sortField: "updatedAt",
    label: "field.updatedDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "updatedBy",
    label: "field.updatedBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "actions",
    label: "field.action",
  },
];
