export default [
  {
    key: "search",
    label: "field.name",
    type: "text",
  },
  {
    key: "itemCode",
    label: "field.itemCode",
    type: "text",
  },
  {
    key: "isAvailable",
    label: "field.available",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.yes", value: "1" },
      { text: "field.no", value: "0" },
    ],
    lg: "auto",
  },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: 6,
  },
  {
    label: "breadcrumb.exportSetting",
    icon: "SettingsIcon",
    type: "divider",
    lg: 12,
    md: 12,
  },
  // {
  //   key: "layout",
  //   label: "field.layout",
  //   rules: "required",
  //   type: "radio",
  //   buttons: false,
  //   cast: "boolean",
  //   options: [
  //     { text: "field.portrait", value: "portrait" },
  //     { text: "field.landscape", value: "landscape" },
  //   ],
  //   cols: 12,
  // },
  {
    key: "type",
    label: "field.type",
    rules: "required",
    type: "radio",
    buttons: false,
    cast: "boolean",
    options: [
      { text: "field.qrcode", value: "qrcode" },
      { text: "field.item", value: "item" },
    ],
    cols: 12,
  },
];
